class logoShrink {
  constructor() {
    this.header = document.querySelector(".header");
    this.logo = document.querySelector("#logo-icon");
    this.links = document.querySelector(".anchor-links");
    this.catchprase = document.querySelector(".header__catchphrase");

    this.events();
  }

  events() {
    window.onscroll = () => {
      this.shrinkLogo();
    };
  }

  shrinkLogo() {
    if (
      document.body.scrollTop > this.header.offsetHeight ||
      document.documentElement.scrollTop > this.header.offsetHeight
    ) {
      this.logo.classList.add("small-logo");
      this.catchprase.classList.add("small-logo");
      if (this.links != null) {
        this.links.classList.add("visible");
      }
    } else {
      this.logo.classList.remove("small-logo");
      this.catchprase.classList.remove("small-logo");
      if (this.links != null) {
        this.links.classList.remove("visible");
      }
    }
  }
}

export default logoShrink;
