class handleModal {
  constructor() {
    this.opendModal = document.querySelectorAll("[data-open-modal]");
    this.body = document.querySelector("body");
    this.scrollPosition = 0;

    this.events();
  }

  events() {
    this.opendModal.forEach((trigger) => {
      trigger.addEventListener("click", (e) => this.openModal(e));
    });
  }

  closeModal(modal) {
    // Reset body scroll ability when modal is closed
    modal.addEventListener("close", (event) => {
      this.body.style.marginTop = 0;
      window.scrollTo(0, this.scrollPosition);
      this.body.classList.remove("lock-scroll");
    });

    // Exit if clicking outside modal :
    // modal.addEventListener("click", (e) => {
    //   const dialogDimensions = modal.getBoundingClientRect();
    //   if (
    //     e.clientX < dialogDimensions.left ||
    //     e.clientX > dialogDimensions.right ||
    //     e.clientY < dialogDimensions.top ||
    //     e.clientY > dialogDimensions.bottom
    //   ) {
    //     this.body.style.marginTop = 0;
    //     window.scrollTo(0, this.scrollPosition);
    //     this.body.classList.remove("lock-scroll");
    //     modal.close();
    //   }
    // });

    // Exit if clicking on button
    const exitModal = modal.querySelector("[data-close-modal]");
    exitModal.addEventListener("click", () => {
      this.body.style.marginTop = 0;
      window.scrollTo(0, this.scrollPosition);
      this.body.classList.remove("lock-scroll");
      modal.close();
    });
  }

  openModal(e) {
    // Open modal
    const modal = e.target
      .closest(".team-member")
      .querySelector("[data-modal]");
    modal.showModal();

    // Get scroll position
    this.scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    this.body.style.marginTop = -this.scrollPosition + "px";
    this.body.classList.add("lock-scroll");

    // Init eventlisteners for closing modal
    this.closeModal(modal);
  }
}

export default handleModal;
