import Swiper, { Navigation } from "swiper";

class ImageSlider {
  constructor(element) {
    const sliderElement = element;
    if (sliderElement) {
      const swiper = new Swiper(sliderElement, {
        modules: [Navigation],
        direction: "horizontal",
        slidesPerView: "auto",
        pagination: false, // Disable default pagination
        autoHeight: false, // Disable auto height adjustment
        draggable: false,
        grabCursor: false,
        keyboard: true,
        navigation: {
          prevEl: sliderElement.querySelector(".post-slider__button--prev"),
          nextEl: sliderElement.querySelector(".post-slider__button--next"),
        },
        observer: true,
        observeParents: true,
      });

      if (swiper.slides.length > 1) {
        const paginationElement = sliderElement.querySelector(
          ".post-slider__pagination"
        );

        // Render custom pagination
        const renderCustomPagination = function (current, total) {
          paginationElement.innerHTML = `
            <div class="swiper-pagination-custom">
              <span class="swiper-pagination-current">${current}</span> /
              <span class="swiper-pagination-total">${total}</span>
            </div>
          `;
        };

        // Update pagination on slide change
        swiper.on("slideChange", function () {
          const currentSlide = swiper.realIndex + 1;
          renderCustomPagination(currentSlide, swiper.slides.length);
        });

        // Initial render
        renderCustomPagination(1, swiper.slides.length);

        // Recalculate swiper height when content has loaded
        swiper.on("lazyImageReady", function () {
          swiper.updateAutoHeight();
        });
      }
    }
  }
}

// Initialize sliders
document.querySelectorAll(".post-slider").forEach(function (element) {
  new ImageSlider(element);
});

export default ImageSlider;
