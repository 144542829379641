class MobileMenu {
  constructor() {
    this.menu = document.querySelector(".header__nav");
    this.toggleMenuBtn = document.querySelector(
      ".header__top-bar__menu-trigger"
    );
    this.burgerIcon = document.querySelector(".header__burger");
    this.header = document.querySelector(".header");

    this.subMenuTriggers = document.querySelectorAll(".menu-item-has-children");
    this.subMenus = document.querySelectorAll(".sub-menu");

    this.body = document.querySelector("body");

    this.anchorsLinks = document.querySelector(".anchor-links");

    this.setInitialAriaStateSubMenus();
    this.events();
  }

  events() {
    this.toggleMenuBtn.addEventListener("click", () => this.toggleMenu());

    this.subMenuTriggers.forEach((trigger) => {
      trigger.addEventListener("click", (e) => this.toggleSubMenus(e));
    });
  }

  toggleMenu() {
    this.header.classList.toggle("is-open");
    this.menu.classList.toggle("is-active");
    this.burgerIcon.classList.toggle("is-active");
    if (this.anchorsLinks) {
      this.anchorsLinks.classList.toggle("hide");
    }

    // If window resize close the menu
    const closeMenu = () => {
      this.header.classList.remove("is-open");
      this.menu.classList.remove("is-active");
      this.burgerIcon.classList.remove("is-active");
      if (this.anchorsLinks) {
        this.anchorsLinks.classList.remove("hide");
      }
    };
    if (this.header.classList.contains("is-open")) {
      window.addEventListener("resize", closeMenu, true);
    } else {
      window.removeEventListener("resize", closeMenu);
    }
  }

  toggleSubMenus(e) {
    const parentEl = e.target.closest(".menu-item-has-children");
    const subMenu = parentEl.querySelector(".sub-menu");
    parentEl.classList.toggle("is-active");
    subMenu.classList.toggle("active");

    let subMenuAriaExpandedState = subMenu.getAttribute("aria-expanded");
    if (subMenuAriaExpandedState == "true") {
      subMenuAriaExpandedState = "false";
    } else {
      subMenuAriaExpandedState = "true";
    }
    subMenu.setAttribute("aria-expanded", subMenuAriaExpandedState);
  }

  setInitialAriaStateSubMenus() {
    this.subMenus.forEach((subMenu) => {
      subMenu.setAttribute("aria-expanded", "false");
    });
  }
}

export default MobileMenu;
