class quilloFont {
  constructor() {
    this.events();
  }

  events() {
    this.applyClassToKeyword("Quillo");
    this.applyClassToKeyword("Quino");
    this.applyClassToKeyword("LandQultour");
    // window.addEventListener("load", (event) => {
    //    this.applyClassToKeyword("Quillo");
    // });
  }

  applyClassToKeyword(keyword, className) {
    let elements = document.querySelectorAll("*:not(title)");

    elements.forEach(function (element) {
      Array.from(element.childNodes).forEach(function (node) {
        if (node.nodeType === Node.TEXT_NODE) {
          let text = node.textContent;
          let regex = new RegExp("\\b" + keyword + "\\b", "g");

          let replacedText = text.replace(regex, function (match) {
            return '<span class="quillo-font">' + match + "</span>";
          });

          if (replacedText !== text) {
            let fragment = document.createDocumentFragment();
            let tempElement = document.createElement("span");
            tempElement.innerHTML = replacedText;

            Array.from(tempElement.childNodes).forEach(function (childNode) {
              fragment.appendChild(childNode);
            });

            element.replaceChild(fragment, node);
          }
        }
      });
    });
  }
}

export default quilloFont;
