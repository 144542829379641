import "./css/style.scss";

// Modules/Classes
import MobileMenu from "./js/modules/MobileMenu";
import SmoothScrolling from "./js/modules/SmoothScrolling";
import LogoShrink from "./js/modules/LogoShrink";
import HandleModal from "./js/modules/HandleModal";
import QuilloFont from "./js/modules/QuilloFont";
import ImageSlider from "./js/modules/ImageSlider";

// Init Objects from Classes
const mobileMenu = new MobileMenu();
const smoothScrolling = new SmoothScrolling();
const logoShrink = new LogoShrink();
const handleModal = new HandleModal();
const imageSlider = new ImageSlider();

// LazyLoading
import lozad from "lozad";
const lazyLoadObserver = lozad(".lozad", {
  rootMargin: "500px 0px", // syntax similar to that of CSS Margin
  threshold: 0, // ratio of element convergence
  loaded: function (el) {
    el.classList.add("loaded");
    el.classList.remove("lozad");
  },
});
lazyLoadObserver.observe();

// Small Functions
// Fix Transitions Bug on load
window.onload = function () {
  document.querySelector("body").classList.remove("preload");
};
